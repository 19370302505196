<template>
  <div>
    <div class="all-feed-box recived-feedback pt-2">
      <FeedbackLoader v-if="loader" />
      <div
        v-for="(item, index) in sendFeedbacks"
        :key="index"
        class="feedback-content"
      >
        <div class="align-items-center d-flex justify-content-between">
          <div>
            <div>
              <p
                v-if="item.type && item.type == 'project'"
                class="feedback-given feedback-to pt-1 h4 m-0"
              >
                Personal Feedback
              </p>
              <p
                v-else
                class="feedback-given mb-25"
              >
                General Feedback
              </p>
              <p
                v-if="item.project && item.project.name"
                class="feedback-given pt-1 mb-25"
              >
                Project Name :
                <span>{{
                  item.project && item.project.name ? item.project.name : ""
                }}</span>
              </p>

            </div>
            <p
              v-if="item && item.users && item.users.length"
              class="feedback-given feedback-to d-flex align-items-center py-25"
            >
              To:
              <span class="d-flex align-items-center ml-50">
                <span
                  v-for="(member, mindex) in item.users"
                  v-if="mindex < 5"
                  :key="mindex"
                >
                  {{ member.full_name }}
                  {{ item.users.length - 1 == mindex ? null : ", " }}</span>
                <span
                  v-if="item.users.length > 5"
                  v-b-tooltip.hover.top="members(item.users)"
                  class="projecrMembermore"
                >{{ item.users.length - 5 }}+</span>
              </span>
            </p>
          </div>
          <div class="align-items-end all-feedback-svg flex-column">
            <div class="d-flex">
              <small
                class="mr-50"
                style="font-size: 0.95rem"
              > {{ item.created_at | DateFormatFormnowOrLocal }}</small>
              <div
                v-if="!item.is_published"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.editFeedback
                v-b-tooltip.hover.top
                title="Edit Feedback"
                class="d-inline-block"
                @click="openEditFeedbackForm(item)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="18"
                  class="align-middle edit-data-delete"
                />
              </div>
            </div>
            <FeedBackRating
              v-model="item.rating"
              :name="`R-${index}`"
            />
          </div>
        </div>

        <div>
          <v-clamp
            autoresize
            :max-lines="5"
            ellipsis="..."
            location="end"
            class="feedback-description mb-1"
            :style="{ whiteSpace: 'pre-line' }"
          >
            {{ item.description }}
            <template #after="{ toggle, clamped, expanded }">
              <button
                v-if="clamped == true || expanded == true"
                class="see-more-btn"
                @click="toggle"
              >
                {{ clamped == true ? "see more" : "see less" }}
              </button>
            </template>
          </v-clamp>
        </div>
        <p
          v-if="item.updated_by && item.updated_by.id"
          class="d-flex"
          style="font-weight: 500;"
        >Last Edited {{ item.updated_by && item.updated_by.role == 'SA' ? 'by Admin' : '' }}:
          <small
            class="ml-50"
            style="font-size: 0.95rem"
          > {{ item.updated_at | DateFormatFormnowOrLocal }}</small></p>
      </div>
      <infinite-loading
        v-if="datashow"
        slot="append"
        spinner="spiral"
        :distance="1"
        force-use-infinite-wrapper=".all-feed-box"
        @infinite="sendFeedbacksDtl"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
      <div
        v-if="!loader && totalCount == 0"
        class="align-items-center d-flex flex-column h-100 justify-content-center text-center"
      >
        <img
          src="@/assets/images/nodatafound/nofeedback.svg"
          alt="No Feedback Found"
          class="no-data-img"
        >
        <span class="h2 py-2"> No Feedback Found </span>
      </div>
      <EditFeedback
        :item="editData"
        type="givenF"
      />
    </div>
  </div>
</template>
<script>
import FeedbackLoader from '@/components/loaders/feedback-loader.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { eventBus } from '@/main'
import VClamp from 'vue-clamp'
import FeedBackRating from './FeedBackRating.vue'
import EditFeedback from './EditFeedback.vue'

export default {
  name: 'FeedbackSenderList',
  components: {
    FeedBackRating,
    FeedbackLoader,
    InfiniteLoading,
    VClamp,
    EditFeedback,
  },
  props: {
    filterValue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sendFeedbacks: [],
      totalCount: 0,
      loader: false,
      perPage: 25,
      currentPage: 1,
      datashow: true,
      project: [],
      feedback_to: [],
      filter_rating: null,
      start_date: null,
      end_date: null,
      modelId: null,
      editData: [],
    }
  },

  mounted() {
    // this.sendFeedbacksDtl();

    eventBus.$on('feedbackGivenFilters', data => {
      if (data) {
        this.sendFeedbacks = []
        this.currentPage = 1
        this.project = data.project_id && data.project_id.length ? data.project_id : null
        this.feedback_to = data.feedback_to_user && data.feedback_to_user.length
          ? data.feedback_to_user
          : null
        this.filter_rating = data.feedback_rating ? data.feedback_rating : null
        this.start_date = data.start_date ? data.start_date : null
        this.end_date = data.end_date ? data.end_date : null
        this.sendFeedbacksDtl()
      }
    })

    eventBus.$on('reloadGivenFeedbacklist', data => {
      if (data) {
        this.sendFeedbacks = []
        this.currentPage = 1
        this.sendFeedbacksDtl()
      }
    })
  },
  destroyed() {
    eventBus.$off('feedbackGivenFilters')
    eventBus.$off('reloadGivenFeedbacklist')
  },
  methods: {
    async sendFeedbacksDtl($state) {
      this.loader = true
      const input = {
        page: this.currentPage,
        per_page: this.perPage,
        project: this.project,
        feedback_to: this.feedback_to,
        rating: this.filter_rating,
        start_date: this.start_date,
        end_date: this.end_date,
      }
      const response = await this.getHTTPPostResponse(
        '/feedback/send-feedback-list',
        input,
      )

      if (response && response.status == 200) {
        const { data } = response
        // this.sendFeedbacks = data.feedbacks;

        this.sendFeedbacks = [...this.sendFeedbacks, ...data.feedbacks]
        this.totalCount = data.count
        this.loader = false
        this.$store.state.app.feedbackCount.sendFeedbackCount = data.count

        if (this.totalCount >= this.currentPage * this.perPage) {
          this.datashow = true
          this.currentPage += 1
          $state.loaded()
        } else {
          this.datashow = false
          // $state.complete();
        }
      }
      this.loader = false
    },

    members(arr) {
      let str = ''
      for (let i = 5; i < arr.length; i++) {
        str += arr[i] ? arr[i].full_name : null
        arr.length - 1 == i ? str : (str += ' ,')
      }
      return str
    },
    openEditFeedbackForm(item) {
      this.modelId = item.id
      this.editData = item
    },
  },
}
</script>

<style scoped>
@import "../../assets/scss/component-css/feedback.css";

.projecrMembermore {
  font-weight: 500;
  font-size: 10px;
  color: #555555;
  background-color: #fff;
  border: 1px solid #2178fb;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
}
</style>
