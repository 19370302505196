<template>
  <div class="ml-auto mt-0 mr-1 filter-feedback-card">
    <div class="d-flex align-items-center flex-wrap justify-content-between">
      <div class="d-flex align-items-center justify-content-start flex-wrap">
        <p
          class="filter-title"
          style="color: #2178fb"
        >
          Filters :
        </p>

        <div class="w-lg-100">
          <custom-dropdown
            v-if="type == 'all-feedback'"
            ref="givenUser"
            v-model="feedback_status"
            :options="feedbackStatusOption"
            label="Status"
            icon="EditIcon"
            :search-box="false"
            :hide-close="true"
          />
        </div>

        <div class="w-lg-100 position-relative">
          <b-dropdown
            id="dropdown-1"
            v-model="feedback_rating"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="feedback-rating-dropdown ml-1"
          >
            <template #button-content>
              <div
                class="d-flex align-items-center"
                @click="closeDropdown"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="align-middle"
                />
                <span
                  class="mr-1 ml-50"
                >Ratings
                  <feather-icon
                    v-if="feedback_rating"
                    icon="XIcon"
                    size="14"
                    class="m-25"
                    @click="feedback_rating = null"
                  />
                </span>
                <feather-icon icon="ChevronDownIcon" />
              </div>
            </template>

            <b-dropdown-form class="upper-radio-btn">
              <b-form-radio
                v-model="feedback_rating"
                class="mb-1"
                name="rating"
                value="1"
              />
              <b-form-radio
                v-model="feedback_rating"
                class="mb-1"
                name="rating"
                value="2"
              />
              <b-form-radio
                v-model="feedback_rating"
                class="mb-1"
                name="rating"
                value="3"
              />
              <b-form-radio
                v-model="feedback_rating"
                class="mb-1"
                name="rating"
                value="4"
              />
              <b-form-radio
                v-model="feedback_rating"
                class="mb-1"
                name="rating"
                value="5"
              />
            </b-dropdown-form>
            <b-dropdown-item>
              <input
                id="rating-5"
                v-model="feedback_rating"
                type="radio"
                name="radio"
                class="feedback-radio"
                value="1"
                checked
              >

              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
            </b-dropdown-item>
            <b-dropdown-item>
              <input
                v-model="feedback_rating"
                type="radio"
                name="radio"
                class="feedback-radio"
                value="2"
              >
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
            </b-dropdown-item>
            <b-dropdown-item>
              <input
                v-model="feedback_rating"
                type="radio"
                name="radio"
                class="feedback-radio"
                value="3"
              >
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
            </b-dropdown-item>
            <b-dropdown-item>
              <input
                v-model="feedback_rating"
                type="radio"
                name="radio"
                class="feedback-radio"
                value="4"
              >
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
            </b-dropdown-item>
            <b-dropdown-item>
              <input
                v-model="feedback_rating"
                type="radio"
                name="radio"
                class="feedback-radio"
                value="5"
              >
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
              <svg
                id="Glyph"
                version="1.1"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="XMLID_254_"
                  d="M29.845,17.099l-2.489,8.725C26.989,27.105,25.804,28,24.473,28H11c-0.553,0-1-0.448-1-1V13  c0-0.215,0.069-0.425,0.198-0.597l5.392-7.24C16.188,4.414,17.05,4,17.974,4C19.643,4,21,5.357,21,7.026V12h5.002  c1.265,0,2.427,0.579,3.188,1.589C29.954,14.601,30.192,15.88,29.845,17.099z"
                  fill="#fcd93a"
                />
                <path
                  id="XMLID_256_"
                  d="M7,12H3c-0.553,0-1,0.448-1,1v14c0,0.552,0.447,1,1,1h4c0.553,0,1-0.448,1-1V13C8,12.448,7.553,12,7,12z   M5,25.5c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5C6.5,24.828,5.828,25.5,5,25.5z"
                  fill="#fcd93a"
                />
              </svg>
            </b-dropdown-item>
          </b-dropdown>
          <span
            v-if="feedback_rating"
            class="rating-count"
          >1 </span>
        </div>
        <div class="w-lg-100">
          <custom-dropdown
            v-if="type != 'given-feedback'"
            ref="givenUser"
            v-model="given_by_user"
            :options="$store.state.app.usersList"
            label="Given By"
            icon="EditIcon"
            multiple
          />
        </div>

        <div class="w-lg-100">
          <custom-dropdown
            v-if="type != 'received-feedback'"
            ref="toUser"
            v-model="feedback_to_user"
            :options="$store.state.app.usersList"
            label="Feedback To"
            icon="EditIcon"
            multiple
          />
        </div>
        <div class="">
          <custom-dropdown
            ref="project"
            v-model="project"
            :options="$store.state.app.productiveProjectList"
            label="Project"
            multiple
            icon="EditIcon"
          />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="ml-1">
          <button
            class="apply-filter filterRedesign mr-1"
            @click="applyFilter()"
          >
            Apply Filter
          </button>
          <button
            class="apply-filter-clear filterRedesign"
            @click="clearFilter()"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'

export default {
  name: 'FilterSection',
  components: {
    customDropdown,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      project: [],
      given_by_user: [],
      feedback_to_user: [],
      feedback_rating: null,
      start_date: null,
      end_date: null,
      feedback_status: 'all_data',
      feedbackStatusOption: [
        { label: 'All', value: 'all_data' },
        { label: 'Published', value: 'published' },
        { label: 'Pending', value: 'pending' },
      ],
    }
  },
  watch: {
    type() {
      this.given_by_user = []
      this.project = []
      this.feedback_to_user = []
      this.feedback_rating = null
      this.start_date = null
      this.end_date = null
      this.feedback_status = 'all_data'
      this.closeDropdown()
    },
    feedback_status() {
      eventBus.$emit('closeCustomDropDown')
    },
  },
  mounted() {
    eventBus.$on('feedbackDateFilters', data => {
      if (data) {
        this.start_date = data.start_date ? data.start_date : null
        this.end_date = data.end_date ? data.end_date : null
        this.applyFilter()
      }
    })
  },
  destroyed() {
    eventBus.$off('feedbackDateFilters')
  },
  methods: {
    applyFilter() {
      const filter = {
        user_id: this.given_by_user,
        project_id: this.project,
        feedback_to_user: this.feedback_to_user,
        feedback_rating: this.feedback_rating,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.feedback_status,
      }
      if (this.type == 'received-feedback') {
        eventBus.$emit('feedbackReceiveFilters', filter)
      } else if (this.type == 'all-feedback') {
        eventBus.$emit('feedbackFilters', filter)
      } else if (this.type == 'given-feedback') {
        eventBus.$emit('feedbackGivenFilters', filter)
      }
    },
    clearFilter() {
      this.given_by_user = []
      this.project = []
      this.feedback_to_user = []
      this.feedback_rating = null
      this.feedback_status = 'all_data'
      this.applyFilter()
    },
    closeDropdown() {
      console.log('call close dropdown')
      if (this.$refs.toUser) {
        this.$refs.toUser.isVisible = false
      }
      if (this.$refs.givenUser) {
        this.$refs.givenUser.isVisible = false
      }
      if (this.$refs.project && this.$refs.project.isVisible == true) {
        this.$refs.project.isVisible = false
      }
      eventBus.$emit('closeCustomDropDown')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables/_variables.scss";

.card-filter-section {
  // background-color: $white;
  // margin: 10px 0px 25px 0px;
  // // box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  // padding: 10px 15px;

  .filter-title {
    color: $logo-color;
    font-size: 2.25 * $rem;
    font-weight: $fw500;
  }

  .apply-filter {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;
    padding: 8px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;

    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }

  .apply-filter-clear {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    background-color: transparent;

    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
}
</style>
