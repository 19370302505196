<template>
  <div :class="name == 'send' ? 'like' : 'after-feedback'">
    <div class="feedback">
      <div class="rating">
        <input
          id="rating-5"
          v-model="localValue"
          type="radio"
          :name="`rating-${name}`"
          value="5"
        >
        <label for="rating-5" />
        <input
          id="rating-4"
          v-model="localValue"
          type="radio"
          :name="`rating-${name}`"
          value="4"
        >
        <label for="rating-4" />
        <input
          id="rating-3"
          v-model="localValue"
          type="radio"
          :name="`rating-${name}`"
          value="3"
        >
        <label for="rating-3" />
        <input
          id="rating-2"
          v-model="localValue"
          type="radio"
          :name="`rating-${name}`"
          value="2"
        >
        <label for="rating-2" />
        <input
          id="rating-1"
          v-model="localValue"
          type="radio"
          :name="`rating-${name}`"
          value="1"
        >
        <label for="rating-1" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedBackRating',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: [Array, String, Number],
      default: '',
    },
    name: {
      type: [String, Number],
      default: 'send',
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
  },
  mounted() {},
}
</script>

<style scoped>
@import "../../assets/scss/component-css/feedback.css";
</style>
