<template>
  <div>
    <validation-observer ref="feedbackObserverRuls">
      <!-- modal vertical center -->
      <b-modal
        :id="id"
        centered
        content-class="feedback-modal"
        size="lg"
        @shown="modelOpen"
        @hidden="clearAll"
      >
        <template
          #modal-title
          class="pt-75 pl-1"
        >
          <span>Edit Feedback</span>
        </template>
        <div class="p-2">
          <div>
            <div class="feedback-header">
              <h3>Feedback Form</h3>
              <p>
                We would love to hear your thoughts, suggestions, concerns or
                problems with anything so we can improve!
              </p>
            </div>
            <b-row class="align-items-center mt-2">
              <b-col cols="12">
                <div class="feedback-type">
                  <validation-provider
                    #default="{ errors }"
                    name="Feedback rating"
                    rules="required|min:1"
                  >
                    <div class="like">
                      <div class="feedback">
                        <div class="rating">
                          <input
                            id="rating-15"
                            v-model="rating"
                            type="radio"
                            value="5"
                            @click="updateRatingValue(5)"
                          >
                          <label for="rating-15" />
                          <input
                            id="rating-14"
                            v-model="rating"
                            type="radio"
                            value="4"
                            @click="updateRatingValue(4)"
                          >
                          <label for="rating-14" />
                          <input
                            id="rating-13"
                            v-model="rating"
                            type="radio"
                            value="3"
                            @click="updateRatingValue(3)"
                          >
                          <label for="rating-13" />
                          <input
                            id="rating-12"
                            v-model="rating"
                            type="radio"
                            value="2"
                            @click="updateRatingValue(2)"
                          >
                          <label for="rating-12" />
                          <input
                            id="rating-11"
                            v-model="rating"
                            type="radio"
                            value="1"
                            @click="updateRatingValue(1)"
                          >
                          <label for="rating-11" />
                        </div>
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- project select-->
                  <b-form-group
                    v-if="item && item.type && item.type == 'project'"
                    class="mt-2"
                  > <label for="projectName">Project Name </label>

                    <v-select
                      id="projectName"
                      v-model="project_id"
                      label="name"
                      class="select-size-lg"
                      name="project"
                      :options="$store.state.app.productiveProjectList"
                      :reduce="(u) => u.id"
                      placeholder="Select Project Name"
                    />

                  </b-form-group>
                </div>
                <div
                  v-if="item && item.type && item.type == 'project'"
                  class="user-name"
                >
                  <b-form>
                    <div class="project-select2">
                      <b-form-group>
                        <label for="employee">Select Employee <span class="text-danger">*</span></label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee"
                          rules="required"
                        >
                          <v-select
                            id="employee"
                            v-model="assignee_id"
                            label="name"
                            class="select-size-lg"
                            multiple
                            name="employee"
                            :options="feedbackUserList"
                            :reduce="(u) => u.id"
                            placeholder="Select Employee Name"
                            :close-on-select="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-form>
                </div>
              </b-col>
            </b-row>
            <div class="decribe-feedback">
              <b-form>
                <b-form-group
                  label-for="feedback"
                ><label for="feedback">Describe Your Feedback<span class="text-danger"> *</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    rules="required|min:1|max:1024"
                  >
                    <b-form-textarea
                      id="feedback"
                      v-model="description"
                      type="textarea"
                      rows="8"
                      placeholder="Type your feedback..."
                      @keydown="handleKeyDown"
                      @input="handleInput"
                    />

                    <div
                      class="d-flex justify-content-between align-items-center mt-50"
                      style="font-size: 14px; color: grey"
                    >
                      <small class="text-danger">{{ errors[0] }}</small>
                      <span>{{
                        description && description.length
                          ? description.length
                          : 0
                      }}
                        / 1024
                      </span>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </div>
            <div class="submit">
              <button
                class="commonBtn"
                @click="validationForm()"
              >
                Submit Feedback
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </validation-observer>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { eventBus } from '../../main'

export default {
  name: 'EditFeedback',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BModal,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [String, Number],
      required: false,
      default: 'editFeedback',
    },
    item: {
      type: [Array, Object],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      group_members: [],
      rating: 0,
      project_id: null,
      assignee_id: [],
      description: null,
      loader: false,
    }
  },
  computed: {
    // feedback not send itself to user
    feedbackUserList() {
      return this.$store.state.app.usersList.filter(
        e => e.id != this.userInfo.id,
      )
    },
  },
  mounted() {
    this.getProjectsData()
  },
  methods: {
    updateRatingValue(nv) {
      this.rating = nv
    },
    // call emit event
    shareFeedback(withSendername = true) {
      this.$refs.feedbackObserverRuls.validate().then(success => {
        if (success) {
          const obj = {
            user_id: this.group_members,
            feedback_id: this.id,
            is_visible_to_user: true,
            is_visible_to_sender_name: withSendername,
          }
          this.$refs.feedbackObserverRuls.reset()
          this.group_members = []
          this.$emit('shareFeedback', obj)
        }
      })
    },
    // if only one use then defult selected
    modelOpen() {
      this.description = this.item.description
      this.rating = this.item.rating
      this.assignee_id = this.item.users
      this.assignee_id = []
      if (this.item.users && this.item.users.length) {
        this.item.users.forEach(element => {
          this.assignee_id.push(element.id)
        })

        this.assignee_id = this.assignee_id.filter(
          e => e != this.userInfo.id,
        )
      }
      this.project_id = this.item.project_id
    },

    handleInput() {
      if (this.description && this.description.length > 1024) {
        this.description = this.description.substring(0, 1024)
      }
    },
    handleKeyDown(event) {
      if (
        this.description
        && this.description.length >= 1024
        && event.key !== 'Backspace'
      ) {
        event.preventDefault()
      }
    },

    /**
     * check the form validation
     * @returns if success true then call sendFeedback Method
     */
    validationForm() {
      this.$refs.feedbackObserverRuls.validate().then(success => {
        if (success) {
          this.editFeedback()
        }
      })
    },

    // call send feedback Api.
    async editFeedback() {
      this.loader = true
      const input = {
        feedback_id: this.item.id,
        description: this.description,
        project_id: this.project_id,
        user_ids: this.assignee_id,
        rating: this.rating,
        type: this.item.type,
      }

      const response = await this.getHTTPPostResponse(
        '/feedback/update',
        input,
        true,
      )
      if (response && response.status == 200) {
        this.clearAll()
        this.$root.$emit('bv::toggle::modal', this.id)
      }
      this.loader = false

      if (this.type == 'givenF') {
        eventBus.$emit('reloadGivenFeedbacklist', true)
      } else if (this.type == 'allFeedback') {
        eventBus.$emit('reloadAllFeedbacklist', true)
      }
    },

    clearAll() {
      this.description = null
      this.project_id = null
      this.assignee_id = []
      this.rating = null
      this.$refs.feedbackObserverRuls.reset()
    },
    modelClose() {
      this.group_members = []
    },
  },
}
</script>

<style scoped>
@import "../../assets/scss/component-css/feedback.css";
</style>
