<template>
  <div>
    <div class="all-feed-box pt-2 feedback-list-card">
      <FeedbackLoader v-if="loader && currentPage == 1" />
      <div
        v-for="(item, index) in allFeedbacks"
        :key="index"
        class="feedback-content"
      >
        <div class="">
          <b-row>
            <b-col cols="3">
              <div class="d-flex justify-content-between align-items-center">
                <div
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    v-if="item && item.created_by"
                    size="50px"
                    class="newproject-avatar mb-0"
                    :title="item.created_by.full_name"
                    :src="item.created_by.picture"
                    :text="item.created_by.full_name | avatarText"
                  />
                  <div>
                    <p
                      v-if="item && item.created_by"
                      class="feedback-given ml-50"
                    >
                      <span>{{
                        item.created_by &&
                          item.created_by &&
                          item.created_by.full_name
                      }}</span>
                    </p>
                    <div class="all-feedback-svg align-items-center d-flex flex-wrap">
                      <FeedBackRating
                        v-model="item.rating"
                        :name="index"
                      />
                      <small
                        class="ml-50"
                        style="font-size: 0.950rem"
                      >{{
                        item.created_at | DateFormatFormnowOrLocal
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-items-center">
                <p
                  v-if="item.type && item.type == 'project'"
                  class="feedback-given feedback-to pt-1 h4 m-0"
                >
                  Personal Feedback
                </p>
                <p
                  v-else
                  class="feedback-given feedback-to pt-1 h4 m-0"
                >
                  General Feedback
                </p>
                <p
                  v-if="item.project && item.project.name"
                  class="feedback-given feedback-to pt-1 h4 m-0"
                >
                  Project Name :
                  <span>{{
                    item.project && item.project.name ? item.project.name : ""
                  }}</span>
                </p>
              </div>

              <p
                v-if="item && item.users && item.users.length"
                class="feedback-given feedback-to d-flex align-items-center py-25"
              >
                To :
                <span class="d-flex align-items-center ml-50">
                  <span
                    v-for="(member, mindex) in item.users"
                    v-if="mindex < 2"
                    :key="mindex"
                  >
                    {{ member.full_name }}
                    {{ item.users.length - 1 == mindex ? null : ", " }}</span>
                  <span
                    v-if="item.users.length > 2"
                    v-b-tooltip.hover.top="members(item.users)"
                    class="projecrMembermore"
                  >{{ item.users.length - 2 }}+</span>
                </span>
              </p>
            </b-col>
            <b-col cols="8">
              <div>
                <v-clamp
                  autoresize
                  :max-lines="5"
                  ellipsis="..."
                  location="end"
                  class="feedback-description py-1"
                  :style="{ whiteSpace: 'pre-line' }"
                >
                  {{ item.description }}
                  <template #after="{ toggle, clamped, expanded }">
                    <button
                      v-if="clamped == true || expanded == true"
                      class="see-more-btn"
                      @click="toggle"
                    >
                      {{ clamped == true ? "see more" : "see less" }}
                    </button>
                  </template>
                </v-clamp>
              </div>
            </b-col>
            <b-col style="text-align:end">
              <div
                class="d-flex justify-content-end"
              >
                <FeedbackShare
                  v-if="item.type && item.type !== 'general'"
                  :id="item.id"
                  :options="item.users"
                  :is-show-share-with-name-btn="!item.is_share_with_anonymously"
                  @shareFeedback="feedbackUserStatusUpdate"
                />
                <div
                  v-if="item.type && item.type !== 'general'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.editFeedback
                  v-b-tooltip.hover.top
                  title="Edit Feedback"
                  class="d-inline-block ml-1"
                  @click="openEditFeedbackForm(item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                    class="align-middle edit-data-delete"
                  />
                </div>
                <div
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top
                  title="Delete Feedback"
                  class="d-inline-block ml-1"
                  @click="deleteConfirmation(item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="18"
                    class="align-middle edit-data-delete text-danger"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
          <div
            class="d-flex align-items-center"
            :class="item.updated_by && item.updated_by.id ? 'justify-content-between' : 'justify-content-end'"
          >
            <p
              v-if="item.updated_by && item.updated_by.id"
              class="d-flex"
              style="font-weight: 500;"
            >Last Edited {{ item.updated_by && item.updated_by.role == 'SA' ? 'by Admin' : '' }}:
              <small
                class="ml-50"
                style="font-size: 0.95rem"
              > {{ item.updated_at | DateFormatFormnowOrLocal }}</small></p>
            <div
              v-if="item.type && item.type=='project'"
              :class="item.is_published ? 'published' : 'pending'"
            >
              {{ item.is_published ? 'Published' : 'Pending' }}
            </div>
          </div>
        </div>
      </div>
      <infinite-loading
        v-if="datashow"
        slot="append"
        spinner="spiral"
        :distance="1"
        force-use-infinite-wrapper=".all-feed-box"
        @infinite="allFeedbacksDtl"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
      <div
        v-if="loader"
        class="text-center"
      >
        <span class="loader-data" />
      </div>
      <div
        v-if="!loader && totalCount == 0"
        class="align-items-center d-flex flex-column h-100 justify-content-center text-center"
      >
        <img
          src="@/assets/images/nodatafound/nofeedback.svg"
          alt="No Feedback Found"
          class="no-data-img"
        >
        <span class="h2 py-2"> No Feedback Found </span>
      </div>
      <EditFeedback
        :item="editData"
        type="allFeedback"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'
import FeedbackLoader from '@/components/loaders/feedback-loader.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { eventBus } from '@/main'
import VClamp from 'vue-clamp'
import FeedbackShare from './FeedBackSender.vue'
import FeedBackRating from './FeedBackRating.vue'
import EditFeedback from './EditFeedback.vue'

export default {
  name: 'FeedBackListTable',
  components: {
    BRow,
    BCol,
    BAvatar,
    FeedBackRating,
    FeedbackShare,
    FeedbackLoader,
    InfiniteLoading,
    VClamp,
    EditFeedback,
  },
  data() {
    return {
      loader: false,
      currentPage: 1,
      perPage: 25,
      allFeedbacks: [],
      totalCount: 0,
      datashow: true,
      filterProject: [],
      filterUser: [],
      feedback_to: [],
      filter_rating: null,
      start_date: null,
      end_date: null,
      editData: [],
      modelId: null,
      status: null,
    }
  },
  mounted() {
    eventBus.$on('feedbackFilters', data => {
      if (data) {
        this.allFeedbacks = []
        this.currentPage = 1
        this.filterProject = data.project_id && data.project_id.length ? data.project_id : null
        this.filterUser = data.user_id && data.user_id.length ? data.user_id : null
        this.feedback_to = data.feedback_to_user && data.feedback_to_user.length
          ? data.feedback_to_user
          : null
        this.filter_rating = data.feedback_rating ? data.feedback_rating : null
        this.start_date = data.start_date ? data.start_date : null
        this.end_date = data.end_date ? data.end_date : null
        this.status = data.status
        this.allFeedbacksDtl()
      }
    })

    eventBus.$on('reloadAllFeedbacklist', data => {
      if (data) {
        this.allFeedbacks = []
        this.currentPage = 1
        this.allFeedbacksDtl()
      }
    })
  },
  destroyed() {
    eventBus.$off('feedbackFilters')
    eventBus.$off('reloadAllFeedbacklist')
    this.filterProject = []
    this.filterUser = []
    this.feedback_to = []
    this.feedback_rating = null
  },
  methods: {
    async allFeedbacksDtl($state) {
      this.loader = true
      const input = {
        page: this.currentPage,
        per_page: this.perPage,
        project: this.filterProject,
        user_id: this.filterUser,
        feedback_to: this.feedback_to,
        rating: this.filter_rating,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.status,
      }
      const response = await this.getHTTPPostResponse('/feedback', input)
      this.loader = false
      if (response && response.status == 200) {
        const { data } = response
        // this.allFeedbacks = data.feedbacks;
        this.allFeedbacks = [...this.allFeedbacks, ...data.feedbacks]
        this.totalCount = data.count
        this.datashow = false
        this.$store.state.app.feedbackCount.allFeedbackCount = data.count

        if (this.totalCount >= this.currentPage * this.perPage) {
          this.datashow = true
          this.currentPage += 1
          $state.loaded()
        } else {
          this.datashow = false
          // $state.complete();
        }
      } else {
        this.loader = false
      }
    },

    members(arr) {
      let str = ''
      for (let i = 2; i < arr.length; i++) {
        str += arr[i] ? arr[i].full_name : null
        arr.length - 1 == i ? str : (str += ' ,')
      }
      return str
    },

    // Allow to saw feedback for normar user
    async feedbackUserStatusUpdate(data) {
      const response = await this.getHTTPPostResponse(
        '/feedback/user/status',
        data,
        true,
      )

      if (response && response.status == 200) {
        this.$root.$emit('bv::hide::modal', data.feedback_id)
        this.allFeedbacks = []
        this.currentPage = 1
        this.allFeedbacksDtl()
      }
    },

    openEditFeedbackForm(item) {
      this.modelId = item.id
      this.editData = item
    },

    /**
     * @description confirmation to delete feedback
     * @param {id} feedback id
     * @returns if conform to delete call deleteFeedback function
     */
    async deleteConfirmation(id) {
      const value = await this.conformationAlert(true)
      if (value.isConfirmed === true) {
        this.allFeedbacks = []
        this.deleteFeedback(id)
      }
    },

    async deleteFeedback(id) {
      const response = await this.getHTTPDeleteResponse(
        `/feedback/delete/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.currentPage = 1
        this.allFeedbacksDtl()
      }
    },
  },
}
</script>
<style scoped>
@import "../../assets/scss/component-css/feedback.css";

.projecrMembermore {
  font-weight: 500;
  font-size: 10px;
  color: #555555;
  background-color: #fff;
  border: 1px solid #2178fb;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
}

.loader-data {
  display: inline-block;
    width: 28px;
    height: 28px;
    border: 2px solid #777;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }
</style>
