<template>
  <div>
    <div class="all-feed-box recived-feedback pt-2">
      <FeedbackLoader v-if="loader" />
      <div
        v-for="(item, index) in recivedFeedbacks"
        :key="index"
        class="feedback-content"
      >
        <div class="align-items-center  d-flex justify-content-between">
          <div>
            <div>
              <p
                v-if="item.type && item.type == 'project'"
                class="feedback-given feedback-to pt-1 h4 m-0"
              >
                Personal Feedback
              </p>
              <p
                v-if="item.project && item.project.name"
                class="feedback-given pt-1 mb-25"
              >
                Project Name :
                <span>{{
                  item.project && item.project.name ? item.project.name : ""
                }}</span>
              </p>
              <p
                v-else-if="item.type && item.type == 'general'"
                class="feedback-given mb-25"
              >
                General Feedback
              </p>
              <p
                v-if="item.created_by && item.created_by.full_name"
                class="feedback-given py-25 mb-1"
              >
                From :
                <span>{{
                  item.created_by && item.created_by && item.created_by.full_name
                }}</span>
              </p>
            </div>
          </div>

          <div class="align-items-end all-feedback-svg flex-column">
            <small
              class="mr-50"
              style="font-size: 0.95rem"
            > {{ userInfo.role == 'SA' ? item.created_at : item.users[0].pivot.shared_date | DateFormatFormnowOrLocal }}</small>
            <FeedBackRating
              v-model="item.rating"
              :name="`R-${index}`"
            />
          </div>
        </div>
        <div>
          <v-clamp
            autoresize
            :max-lines="5"
            ellipsis="..."
            location="end"
            class="feedback-description mb-1"
            :style="{ whiteSpace: 'pre-line' }"
          >
            {{ item.description }}
            <template #after="{ toggle, clamped, expanded }">
              <button
                v-if="clamped == true || expanded == true"
                class="see-more-btn"
                @click="toggle"
              >
                {{ clamped == true ? "see more" : "see less" }}
              </button>
            </template>
          </v-clamp>
        </div>
      </div>
      <infinite-loading
        v-if="datashow"
        slot="append"
        spinner="spiral"
        :distance="1"
        force-use-infinite-wrapper=".all-feed-box"
        @infinite="recivedFeedbackDtl"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
      <div
        v-if="!loader && totalCount == 0"
        class="align-items-center d-flex flex-column h-100 justify-content-center text-center"
      >
        <img
          src="@/assets/images/nodatafound/nofeedback.svg"
          alt="No Feedback Found"
          class="no-data-img"
        >
        <span class="h2 py-2"> No Feedback Found </span>
      </div>
    </div>
  </div>
</template>
<script>
import FeedbackLoader from '@/components/loaders/feedback-loader.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { eventBus } from '@/main'
import VClamp from 'vue-clamp'
import FeedBackRating from './FeedBackRating.vue'

export default {
  name: 'FeedBackReciver',
  components: {
    FeedBackRating,
    FeedbackLoader,
    InfiniteLoading,
    VClamp,
  },
  data() {
    return {
      recivedFeedbacks: [],
      totalCount: 0,
      loader: false,
      perPage: 25,
      currentPage: 1,
      datashow: true,
      project: [],
      user_id: [],
      filter_rating: null,
      start_date: null,
      end_date: null,
    }
  },

  mounted() {
    // this.recivedFeedbackDtl();

    eventBus.$on('feedbackReceiveFilters', data => {
      if (data) {
        this.recivedFeedbacks = []
        this.currentPage = 1
        this.project = data.project_id && data.project_id.length ? data.project_id : null
        this.user_id = data.user_id && data.user_id.length ? data.user_id : null
        this.filter_rating = data.feedback_rating ? data.feedback_rating : null
        this.start_date = data.start_date ? data.start_date : null
        this.end_date = data.end_date ? data.end_date : null
        this.recivedFeedbackDtl()
      }
    })
  },
  destroyed() {
    eventBus.$off('feedbackReceiveFilters')
  },
  methods: {
    async recivedFeedbackDtl($state) {
      this.loader = true
      const input = {
        type: 'Received',
        page: this.currentPage,
        per_page: this.perPage,
        project: this.project,
        user_id: this.user_id,
        rating: this.filter_rating,
        start_date: this.start_date,
        end_date: this.end_date,
      }
      const response = await this.getHTTPPostResponse('/feedback', input)

      if (response && response.status == 200) {
        const { data } = response
        // this.recivedFeedbacks = data.feedbacks;

        this.recivedFeedbacks = [...this.recivedFeedbacks, ...data.feedbacks]
        this.totalCount = data.count
        this.loader = false
        this.$store.state.app.feedbackCount.receviedFeedbackCount = data.count

        if (this.totalCount >= this.currentPage * this.perPage) {
          this.datashow = true
          this.currentPage += 1
          $state.loaded()
        } else {
          this.datashow = false
          // $state.complete();
        }
      }
      this.loader = false
    },
  },
}
</script>

<style scoped>
@import "../../assets/scss/component-css/feedback.css";
</style>
