<template>
  <div>
    <div class="card-filter-section d-flex justify-content-between align-items-center">
      <h3 class="table-title-text mb-0">
        Feedback
      </h3>
      <div class="feedback-cal">
        <FilterCal
          v-if="activeTab != 'send-feedback'"
          :start="startDate"
          :end="endDate"
          :active-btn="activeBtn"
          :from="'feedback'"
          @date="updateDate"
        />
      </div>
    </div>
    <div class="card-filter-section pt-1 px-0 pb-0">
      <b-tabs>
        <template #tabs-end>
          <FilterSection
            v-if="activeTab != 'send-feedback'"
            :type="activeTab"
          />
        </template>
        <!-- Send Feedback -->
        <b-tab
          active
          title="Send Feedback"
          @click="activeTab = 'send-feedback'"
        >
          <b-col class="feedback-form">
            <b-row>
              <b-col cols="6">
                <validation-observer ref="feedbackObserverRuls">
                  <div>
                    <div class="feedback-header">
                      <h3>Feedback Form</h3>
                      <p>
                        We would love to hear your thoughts, suggestions,
                        concerns or problems with anything so we can improve!
                      </p>
                    </div>
                    <b-row class="align-items-center mt-2">
                      <b-col cols="12">
                        <div class="feedback-type">
                          <div class="align-items-end d-flex justify-content-between">
                            <div>
                              <div class="feedback-title">
                                <h5>Feedback Type</h5>
                              </div>
                              <div class="feed-group-btn">
                                <button
                                  class="feedback-btn"
                                  :class="!is_projectList_Visiabl
                                    ? 'selected_feedback_type'
                                    : ''
                                  "
                                  @click="is_projectList_Visiabl = false"
                                >
                                  General
                                </button>
                                <button
                                  class="feedback-btn"
                                  :class="is_projectList_Visiabl
                                    ? 'selected_feedback_type'
                                    : ''
                                  "
                                  @click="is_projectList_Visiabl = true"
                                >
                                  Personal
                                </button>
                              </div>
                            </div>

                            <div class="align-items-center d-flex justify-content-center">
                              <validation-provider
                                #default="{ errors }"
                                name="Feedback rating"
                                rules="required|min:1|max:512"
                              >
                                <FeedBackRating v-model="userRating" />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </div>
                          </div>
                          <!-- project select-->
                          <b-form-group
                            v-if="is_projectList_Visiabl"
                            class="mt-2"
                          >
                            <label for="projectName">Project Name</label>
                            <!-- <validation-provider
                              #default="{ errors }"
                              name="Project"
                              rules="required"
                            > -->
                            <v-select
                              id="projectName"
                              v-model="project_id"
                              label="name"
                              class="select-size-lg"
                              name="project"
                              :options="$store.state.app.productiveProjectList
                              "
                              :reduce="(u) => u.id"
                              placeholder="Select Project Name"
                            >
                              <template v-slot:option="option">
                                {{ option.name }}
                              </template>
                              <template #selected-option="option">
                                {{ option.name }}</template>
                            </v-select>
                            <!-- <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> -->
                          </b-form-group>
                        </div>
                        <div class="user-name">
                          <b-form>
                            <div class="project-select2">
                              <b-form-group v-if="is_projectList_Visiabl">
                                <label for="employee">Select Employee
                                  <span class="text-danger">*</span></label>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Employee"
                                  rules="required"
                                >
                                  <v-select
                                    id="employee"
                                    v-model="assignee_id"
                                    label="name"
                                    class="select-size-lg"
                                    multiple
                                    name="employee"
                                    :options="feedbackUserList"
                                    :reduce="(u) => u.id"
                                    placeholder="Select Employee Name"
                                    :close-on-select="false"
                                    :show="showSelect"
                                    @click.native="closeSelectItemList"
                                  >
                                    <template
                                      v-if="showSelect"
                                      v-slot:option="option"
                                    >
                                      {{ option.name }}
                                    </template>
                                    <template #selected-option="option">
                                      {{ option.full_name }}</template>
                                  </v-select>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                              <p v-else>
                                Your feedback matters! Help us improve by
                                sharing your general thoughts . We value your
                                input and strive to continually enhance your
                                experience with Zignuts.
                              </p>
                            </div>
                          </b-form>
                        </div>
                      </b-col>
                    </b-row>
                    <div class="decribe-feedback">
                      <b-form>
                        <b-form-group>
                          <label for="feedback">Describe Your Feedback
                            <span class="text-danger">*</span></label>
                          <validation-provider
                            #default="{ errors }"
                            name="Description"
                            rules="required|min:1|max:1024"
                          >
                            <b-form-textarea
                              id="feedback"
                              v-model="description"
                              type="textarea"
                              rows="8"
                              placeholder="Type your feedback..."
                              @keydown="handleKeyDown"
                              @input="handleInput"
                            />

                            <div
                              class="d-flex justify-content-between align-items-center mt-50"
                              style="font-size: 14px; color: grey"
                            >
                              <small class="text-danger">{{ errors[0] }}</small>
                              <span>{{
                                description && description.length
                                  ? description.length
                                  : 0
                              }}
                                / 1024
                              </span>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </b-form>
                    </div>
                    <div class="Send_anonymously">
                      <b-form-checkbox
                        id="SendFeedback"
                        v-model="isShareWithAnonymous"
                        class="feedback_check"
                      >
                        <label for="SendFeedback">Send anonymously</label>
                      </b-form-checkbox>
                    </div>
                    <div class="Send_anonymously mt-25">
                      <p>Note : By checking this box, your feedback will remain private and won't appear in the given feedback section.
                        <br>
                        Rest assured, we do not store any of your data, ensuring your privacy and confidentiality within the platform.
                      </p>
                    </div>
                    <div class="submit">
                      <button
                        class="commonBtn"
                        :disabled="loader"
                        @click="validationForm()"
                      >
                        Submit Feedback
                      </button>
                    </div>
                  </div>
                </validation-observer>
              </b-col>
              <b-col
                cols="6"
                class="text-center"
              >
                <img
                  src="../../assets/images/pages/Feedback-rafiki.svg"
                  alt=""
                  class="feedback-img"
                >
              </b-col>
            </b-row>
          </b-col>
        </b-tab>
        <!-- Send FeedBack list-->
        <b-tab
          title=""
          @click="activeTab = 'given-feedback'"
        >
          <template #title>
            Given Feedback<span class="feedback-count ml-50">[ {{ $store.state.app.feedbackCount.sendFeedbackCount }}
              ]</span>
          </template>
          <FeedbackSenderList v-if="activeTab == 'given-feedback'" />
        </b-tab>
        <!-- Recived FeedBack -->
        <b-tab @click="activeTab = 'received-feedback'">
          <template #title>
            <!-- {{ $store.state.app.feedbackCount }} -->
            Received Feedback<span class="feedback-count ml-50">[
              {{ $store.state.app.feedbackCount.receviedFeedbackCount }} ]</span>
          </template>
          <FeedbackReciver v-if="activeTab == 'received-feedback'" />
        </b-tab>

        <!-- All FeedBack -->
        <b-tab
          v-if="userInfo && userInfo.role == 'SA'"
          @click="activeTab = 'all-feedback'"
        >
          <template #title>
            All Feedback<span class="feedback-count ml-50">[ {{ $store.state.app.feedbackCount.allFeedbackCount }}
              ]</span>
          </template>
          <FeedBackListTable v-if="activeTab == 'all-feedback'" />
        </b-tab>
      </b-tabs>
    </div>

    <div />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eventBus } from '@/main'
import FilterCal from '../TaskSummary/calender.vue'
import FeedBackRating from './FeedBackRating.vue'
import FeedbackReciver from './FeedBackReciver.vue'
import FilterSection from './Filter.vue'
import FeedbackSenderList from './FeedbackSendList.vue'
import FeedBackListTable from './FeedbackList.vue'

export default {
  name: 'FeedBackTable',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BTabs,
    BTab,
    FeedBackRating,
    ValidationObserver,
    ValidationProvider,
    FeedbackReciver,
    FilterSection,
    FeedbackSenderList,
    FeedBackListTable,
    FilterCal,
  },
  data() {
    return {
      loader: false,
      description: null,
      assignee_id: [],
      project_id: null,
      is_projectList_Visiabl: false,
      userRating: null,
      activeTab: 'send-feedback',
      type: null,
      showSelect: false,
      startDate: null,
      endDate: null,
      activeBtn: null,
      isShareWithAnonymous: false,
    }
  },
  computed: {
    // feedback not send itself to user
    feedbackUserList() {
      return this.$store.state.app.usersList.filter(
        e => e.id != this.userInfo.id,
      )
    },
  },
  watch: {
    is_projectList_Visiabl() {
      this.clearAll()
    },
    activeTab() {
      this.startDate = null
      this.endDate = null
      this.activeBtn = null
      this.getAllFeedbackCount()
    },
  },
  mounted() {
    if (
      this.$store.state.app.usersList
      && this.$store.state.app.usersList.length == 0
    ) {
      this.userList()
    }
    this.getProjectsData()
    this.getAllFeedbackCount()
  },
  methods: {
    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null
      this.endDate = obj && obj.endDate ? obj.endDate : null
      this.activeBtn = obj && obj.active ? obj.active : null
      const filter = {
        start_date: this.startDate,
        end_date: this.endDate,
      }
      eventBus.$emit('feedbackDateFilters', filter)
    },
    /**
     * check the form validation
     * @returns if success true then call sendFeedback Method
     */
    validationForm() {
      this.$refs.feedbackObserverRuls.validate().then(success => {
        if (success) {
          this.sendFeedback()
        }
      })
    },

    // call send feedback Api.
    async sendFeedback() {
      this.loader = true
      const input = {
        description: this.description,
        project_id: this.project_id,
        user_ids: this.assignee_id,
        type: this.is_projectList_Visiabl ? 'project' : 'general',
        rating: this.userRating,
        is_share_with_anonymously: this.isShareWithAnonymous,
      }

      const response = await this.getHTTPPostResponse(
        '/feedback/create',
        input,
        true,
      )
      if (response && response.status == 200) {
        this.clearAll()
        this.getAllFeedbackCount()
      }
      this.loader = false
    },

    // Clear Form data
    clearAll() {
      this.description = null
      this.project_id = null
      this.assignee_id = []
      this.userRating = null
      this.isShareWithAnonymous = false
      this.$refs.feedbackObserverRuls.reset()
    },

    closeSelectItemList(event) {
      // Click is outside menu items, stop propagation
      event.stopPropagation()
    },

    handleInput() {
      if (this.description && this.description.length > 1024) {
        this.description = this.description.substring(0, 1024)
      }
    },
    handleKeyDown(event) {
      if (
        this.description
        && this.description.length >= 1024
        && event.key !== 'Backspace'
      ) {
        event.preventDefault()
      }
    },

    async getAllFeedbackCount() {
      const response = await this.getHTTPPostResponse(
        '/feedback/feedback-count',
        [],
        false,
      )
      if (response && response.status == 200) {
        const { data } = response
        const obj = {
          sendFeedbackCount: data.send_feedback_count,
          receviedFeedbackCount: data.received_feedback_count,
          allFeedbackCount: data.all_feedback_count
            ? data.all_feedback_count
            : 0,
        }
        this.$store.commit('app/UPDATE_FEEDBACKCOUNT', obj)
      }
    },
  },
}
</script>
<style>
@import "../../assets/scss/component-css/feedback.css";
</style>
