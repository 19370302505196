<template>
  <div>
    <div
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal="id"
      v-b-tooltip.hover.top
      title="Share Feedback"
      class="d-inline-block"
    >
      <feather-icon
        icon="Share2Icon"
        size="21"
        class="align-middle edit-data-delete"
      />
    </div>
    <validation-observer ref="feedbackObserverRuls">
      <!-- modal vertical center -->
      <b-modal
        :id="id"
        centered
        content-class="feedback-modal"
        size="lg"
        @shown="modelOpen"
      >
        <template
          #modal-title
          class="pt-75 pl-1"
        >
          <span>Share feedback to employees</span>
        </template>
        <div class="p-2">
          <b-form-group
            label="Select Employee"
            label-for="group_manage"
          >
            <validation-provider
              #default="{ errors }"
              name="Employee"
              rules="required"
            >
              <v-select
                id="tags"
                v-model="group_members"
                multiple
                name="tags"
                label="full_name"
                value="id"
                :options="options"
                :reduce="(u) => u.id"
                class="select-tags-timesheet2"
                placeholder="Select Any Member"
                :close-on-select="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
          <div class="text-center">
            <button
              class="commonBtn"
              @click="shareFeedback(false)"
            >
              Share Anonymous
            </button>
            <button
              v-if="isShowShareWithNameBtn"
              class="commonBtn-outline ml-1"
              @click="shareFeedback(true)"
            >
              Share With Name
            </button>
          </div>
          <div v-if="isFeedBackVisiable">
            <p

              style="font-size: 1.2rem;font-weight: bold; color: #2178fb;"
            >
              Feedback logs :
            </p>
            <div v-for="(item, index) in options">
              <p
                v-if="item.pivot.is_visible_to_user"
                class="d-flex mt-1"
                style="font-weight: 500;"
              >Feedback shared with
                <small
                  class="ml-50"
                  style="font-size: 1rem;font-weight: bold;"
                >{{ item.full_name }} {{
                  item.pivot.is_visible_to_sender_name ? '(With name)' : '(Anonymously)' }}</small>
                <span
                  v-if="item.pivot.shared_date"
                  class="ml-25"
                >on {{ item.pivot.shared_date | formatDateDDMMYYYY }} </span>
                <span
                  v-else
                  class="ml-25"
                >--</span>
              </p>
            </div>
          </div>
        </div>
      </b-modal>
    </validation-observer>
  </div>
</template>
<script>
import {
  BFormGroup,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'FeedBackShare',
  components: {
    BFormGroup,
    BModal,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [String, Number],
      required: true,
      default: 'id',
    },
    isShowShareWithNameBtn: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      group_members: [],
    }
  },
  computed: {
    /** show Feedback log */
    isFeedBackVisiable() {
      return this.options.some(obj => obj.pivot.is_visible_to_user == true)
    },
  },
  methods: {
    // call emit event
    shareFeedback(withSendername = true) {
      this.$refs.feedbackObserverRuls.validate().then(success => {
        if (success) {
          const obj = {
            user_id: this.group_members,
            feedback_id: this.id,
            is_visible_to_user: true,
            is_visible_to_sender_name: withSendername,
          }
          this.$refs.feedbackObserverRuls.reset()
          this.group_members = []
          this.$emit('shareFeedback', obj)
        }
      })
    },

    // if only one use then defult selected
    modelOpen() {
      if (this.options && this.options.length == 1) {
        this.group_members = [this.options[0].id]
      }
    },

  },
}
</script>

<style scoped>
@import "../../assets/scss/component-css/feedback.css";
</style>
